import { AppHeader, TopNav } from "@yunex/yds-react";
import UserMenu from "./UserMenu";

export default function Header() {
  return (
    <AppHeader appName={"Priotalker Access Management"}>
      <TopNav aria-label="navigation" disablePadding>
        <UserMenu />
      </TopNav>
    </AppHeader>
  );
}
