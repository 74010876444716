import { StatusInfo16, Trash16 } from "@yunex/yds-icons";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  SectionField,
  Switch,
  TextField,
  TextFormInput,
} from "@yunex/yds-react";
import { useTheme } from "@yunex/yds-react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { NewUserBody, RoleRepresentation, UserDoc } from "../Users/User";
import { GroupBody } from "../Groups/Group";
import AccessManagementContext from "../../../store/access-management-context";

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const NewUserFormDialog: React.FC<{
  newUserDialogOpen: boolean;
  setNewUserDialogOpen: Dispatch<SetStateAction<boolean>>;
  user?: UserDoc;
}> = (props) => {
  const context = useContext(AccessManagementContext);

  const { palette } = useTheme();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [priorityProduction, setPriorityProduction] = useState(false);
  const [groups, setGroups] = useState<GroupBody[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupBody[]>([]);
  const [rolesProd, setRolesProd] = useState<RoleRepresentation[]>([]);
  const [selectedRolesProd, setSelectedRolesProd] = useState<
    RoleRepresentation[]
  >([]);
  const [rolesAccp, setRolesAccp] = useState<RoleRepresentation[]>([]);
  const [selectedRolesAccp, setSelectedRolesAccp] = useState<
    RoleRepresentation[]
  >([]);
  const [rolesTest, setRolesTest] = useState<RoleRepresentation[]>([]);
  const [selectedRolesTest, setSelectedRolesTest] = useState<
    RoleRepresentation[]
  >([]);

  const dialogTitle = props.user ? "Edit user" : "Create user";

  useEffect(() => {
    const setAllRoles = async () => {
      if (!context.roles.value) return;
      const roleProd = context.roles.value.filter((val) =>
        val.name.endsWith("_production")
      );
      if (roleProd)
        setRolesProd(roleProd.filter((val) => val.name.startsWith("vehicle:")));
      const roleAccp = context.roles.value.filter((val) =>
        val.name.endsWith("_acceptance")
      );
      if (roleAccp)
        setRolesAccp(roleAccp.filter((val) => val.name.startsWith("vehicle:")));
      const roleTest = context.roles.value.filter((val) =>
        val.name.endsWith("_testbed")
      );
      if (roleTest)
        setRolesTest(roleTest.filter((val) => val.name.startsWith("vehicle:")));
    };
    setAllRoles();

    if (!context.groups.value) return;
    setGroups(context.groups.value);
  }, [context.roles.value, context.groups.value]);

  const dialogReset = () => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      priorityProduction: false,
      priorityProductionFrom: "",
      priorityProductionTill: "",
      enabled: true,
    });
    setSelectedGroups([]);
    setSelectedRolesProd([]);
    setSelectedRolesAccp([]);
    setSelectedRolesTest([]);
  };
  const onCloseHandler = async () => {
    props.setNewUserDialogOpen(false);

    await sleep(200);

    dialogReset();
  };

  const onCloseWithConfirmHandler = async () => {
    if (isEdited) {
    }
    onCloseHandler();
  };

  const onSubmitHandler = async (data: any) => {
    var userGroups: NewUserBody["groups"] = selectedGroups.map((group) => {
      return { id: group.id!, name: group.name };
    });
    var userRoles: NewUserBody["roles"] = [];
    selectedRolesProd.map((role) => {
      return userRoles!.push({ id: role.id, name: role.name });
    });
    selectedRolesAccp.map((role) => {
      return userRoles!.push({ id: role.id, name: role.name });
    });
    selectedRolesTest.map((role) => {
      return userRoles!.push({ id: role.id, name: role.name });
    });
    let userId: string | undefined = undefined;
    if (props.user) userId = props.user.id;
    const user: NewUserBody = {
      userValues: {
        id: userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyName: data.companyName,
        enabled: data.enabled,
        priorityProduction: data.priorityProduction,
        priorityProductionFrom: data.priorityProductionFrom,
        priorityProductionTill: data.priorityProductionTill,
      },
      groups: userGroups,
      roles: userRoles,
    };
    var requestOptions;
    if (userId) {
      requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
      };
    } else {
      requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
      };
    }
    await fetch("/api/user", requestOptions);
    context.users.set();
    onCloseHandler();
  };

  const onDeleteHandler = async () => {
    if (!window.confirm(`Are you sure you want to delete this user?`)) return;

    setIsConfirming(true);

    const response = await fetch("/api/user/" + props.user!.id, {
      method: "DELETE",
    });
    const responseData = await response.json();
    if (response.status !== 200) {
      console.error(responseData);
      alert("Could not delete user:\n\n" + responseData.message);
      setIsConfirming(false);
      return;
    } else {
      context.users.set();
      await onCloseHandler();
    }
  };
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required("Enter a first name")
      .max(30, "First name should not be longer then 30 characters"),
    lastName: yup
      .string()
      .trim()
      .required("Enter a last name")
      .max(30, "Last name should not be longer then 30 characters"),
    email: yup.string().trim().required("Please enter a email").email(),
    companyName: yup
      .string()
      .trim()
      .max(30, "Company name should not be longer then 30 characters"),
    priorityProduction: yup.boolean(),
    priorityProductionFrom: yup
      .string()
      .trim()
      .matches(
        /^(\s*|\d{1,2}-\d{1,2}-\d{4})$/,
        "Enter a valid date DD-MM-YYYY"
      ),
    priorityProductionTill: yup
      .string()
      .trim()
      .matches(
        /^(\s*|\d{1,2}-\d{1,2}-\d{4})$/,
        "Enter a valid date DD-MM-YYYY"
      ),
    enabled: yup.boolean().required(),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      companyName: "",
      email: "",
      enabled: true,
      firstName: "",
      lastName: "",
      priorityProduction: false,
      priorityProductionFrom: "",
      priorityProductionTill: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    if (props.user) {
      if (props.user.firstName) setValue("firstName", props.user.firstName);
      if (props.user.lastName) setValue("lastName", props.user.lastName);
      if (props.user.email) setValue("email", props.user.email);
      if (props.user.enabled != null) setValue("enabled", props.user.enabled);
      if (props.user.priority_production != null) {
        setValue("priorityProduction", props.user.priority_production);
        setPriorityProduction(props.user.priority_production);
      }
      if (props.user.priority_production_from)
        setValue("priorityProductionFrom", props.user.priority_production_from);
      if (props.user.priority_production_till)
        setValue("priorityProductionTill", props.user.priority_production_till);
      if (props.user.attributes && props.user.attributes["companyName"])
        setValue(
          "companyName",
          props.user.attributes["companyName"].toString()
        );
      if (props.user.groups)
        setSelectedGroups(
          props.user.groups.map((val) => {
            return { id: val.id, name: val.name, values: {} };
          })
        );
      let userRoles = props.user.roles;
      if (userRoles) {
        var rolesProd = userRoles.filter((val) =>
          val.name!.endsWith("_production")
        );
        var rolesAccp = userRoles.filter((val) =>
          val.name!.endsWith("_acceptance")
        );
        var rolesTest = userRoles.filter((val) =>
          val.name!.endsWith("_testbed")
        );
        setSelectedRolesProd(rolesProd);
        setSelectedRolesAccp(rolesAccp);
        setSelectedRolesTest(rolesTest);
      }
    }
  }, [props.user, setValue, props.newUserDialogOpen]);
  return (
    <Dialog
      fullWidth={true}
      open={props.newUserDialogOpen}
      onClose={onCloseWithConfirmHandler}
    >
      <DialogTitle
        icon={StatusInfo16}
        iconProps={{ sx: { fill: palette.primary.main } }}
        actionButton={
          props.user && {
            icon: <Trash16 color="red" />,
            ariaLabel: "remove-group",
            onClick: onDeleteHandler,
          }
        }
      >
        {dialogTitle}
      </DialogTitle>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <DialogContent>
          <SectionField insets value="Personal details">
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  {...{ control }}
                  name="firstName"
                  render={({ field }) => (
                    <TextFormInput
                      {...field}
                      onChange={(event: any) => {
                        field.onChange(event);
                        setIsEdited(true);
                      }}
                      errorMessage={errors.firstName?.message}
                      label="First name"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  {...{ control }}
                  name="lastName"
                  render={({ field }) => (
                    <TextFormInput
                      {...field}
                      onChange={(event: any) => {
                        field.onChange(event);
                        setIsEdited(true);
                      }}
                      errorMessage={errors.lastName?.message}
                      label="Last name"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  {...{ control }}
                  name="email"
                  render={({ field }) => (
                    <TextFormInput
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        setIsEdited(true);
                      }}
                      errorMessage={errors.email?.message}
                      label="Email"
                      type="email"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  {...{ control }}
                  name="companyName"
                  render={({ field }) => (
                    <TextFormInput
                      {...field}
                      onChange={(event: any) => {
                        field.onChange(event);
                        setIsEdited(true);
                      }}
                      errorMessage={errors.companyName?.message}
                      label="Company name"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </SectionField>
          <SectionField insets value="Roles & Groups">
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedRolesProd}
                  options={rolesProd}
                  onChange={(_event, newValue) => {
                    setSelectedRolesProd(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    let name = option.name
                      .replace(/vehicle:/g, "")
                      .replace(/_production/g, "");
                    let displayName =
                      name.charAt(0).toUpperCase() + name.slice(1);
                    return displayName;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Production roles" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedRolesAccp}
                  options={rolesAccp}
                  onChange={(_event, newValue) => {
                    setSelectedRolesAccp(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    let name = option.name
                      .replace(/vehicle:/g, "")
                      .replace(/_acceptance/g, "");
                    let displayName =
                      name.charAt(0).toUpperCase() + name.slice(1);
                    return displayName;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Acceptance roles" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedRolesTest}
                  options={rolesTest}
                  onChange={(_event, newValue) => {
                    setSelectedRolesTest(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    let name = option.name
                      .replace(/vehicle:/g, "")
                      .replace(/_testbed/g, "");
                    let displayName =
                      name.charAt(0).toUpperCase() + name.slice(1);
                    return displayName;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Testbed roles" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedGroups}
                  options={groups}
                  onChange={(_event, newValue) => {
                    setSelectedGroups(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="User groups" />
                  )}
                />
              </Grid>
            </Grid>
          </SectionField>
          <SectionField insets value="Priority">
            <Grid container rowSpacing={1} columnSpacing={2} alignItems="end">
              <Grid item xs={3}>
                <Controller
                  name="priorityProduction"
                  {...{ control }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          onChange={(event) => {
                            field.onChange(event);
                            setPriorityProduction(!field.value);
                            setIsEdited(true);
                          }}
                          checked={field.value}
                        />
                      }
                      label="Production"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="priorityProductionFrom"
                  {...{ control }}
                  render={({ field }) => (
                    <TextFormInput
                      disabled={!priorityProduction}
                      required={priorityProduction}
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        setIsEdited(true);
                      }}
                      errorMessage={errors.priorityProductionFrom?.message}
                      placeholder="DD-MM-YYYY"
                      label="Active from"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="priorityProductionTill"
                  {...{ control }}
                  render={({ field }) => (
                    <TextFormInput
                      disabled={!priorityProduction}
                      required={priorityProduction}
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        setIsEdited(true);
                      }}
                      errorMessage={errors.priorityProductionTill?.message}
                      placeholder="DD-MM-YYYY"
                      label="Active till"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </SectionField>
          <SectionField insets value="Active">
            <Grid container rowSpacing={1} columnSpacing={2} alignItems="end">
              <Grid item xs={12}>
                <Controller
                  {...{ control }}
                  name="enabled"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          onChange={(event) => {
                            field.onChange(event);
                            setIsEdited(true);
                          }}
                          checked={field.value}
                        />
                      }
                      label="Is active"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </SectionField>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting || isConfirming}
            type="submit"
          >
            Save
          </Button>
          <Button
            color="secondary"
            disabled={isSubmitting || isConfirming}
            onClick={onCloseWithConfirmHandler}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NewUserFormDialog;
