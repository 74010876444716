import { useState, useEffect, PropsWithChildren } from "react";
import { AccessManagementContextType } from "./access-management-context";
import {
  RoleRepresentation,
  UserDoc,
} from "../components/Management/Users/User";
import AccessManagementContext from "./access-management-context";
import { GroupBody } from "../components/Management/Groups/Group";

const AccessManagementProvider: React.FC<PropsWithChildren> = (props) => {
  const [users, setUsers] = useState<UserDoc[] | undefined>();
  const [groups, setGroups] = useState<GroupBody[] | undefined>();
  const [roles, setRoles] = useState<RoleRepresentation[] | undefined>();

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    const response = await fetch("/api/user");
    if (!response.ok) return;
    let parsedResponse = await response.json();
    let users = parsedResponse.users;
    setUsers(
      users.sort((a: any, b: any) => {
        const nameA = a.email.toUpperCase();
        const nameB = b.email.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };
  const fetchGroups = async () => {
    const response = await fetch("/api/group");
    if (!response.ok) return;
    let parsedResponse = await response.json();
    let groups = parsedResponse.groups;
    setGroups(
      groups.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };

  const fetchRoles = async () => {
    const response = await fetch("/api/role");
    if (!response.ok) return;
    let parsedResponse = await response.json();
    let roles: RoleRepresentation[] = parsedResponse.roles;
    setRoles(
      roles.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };

  const accessManagementContext: AccessManagementContextType = {
    users: { value: users, set: fetchUsers },
    groups: { value: groups, set: fetchGroups },
    roles: { value: roles, set: fetchRoles },
  };

  return (
    <AccessManagementContext.Provider value={accessManagementContext}>
      {props.children}
    </AccessManagementContext.Provider>
  );
};

export default AccessManagementProvider;
