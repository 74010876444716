import { Button, Card, CardContent } from "@yunex/yds-react";
import GroupList from "./GroupList";
import { Add16 } from "@yunex/yds-icons";
import NewGroupFormDialog from "../FormDialogs/NewGroupFormDialog";
import { useState } from "react";

const GroupsOverview = () => {
  const [newGroupDialogOpen, setNewGroupDialogOpen] = useState(false);

  return (
    <Card style={{ height: "100%", width: "100%" }}>
      <CardContent>
        <div>
          <h2>Groups</h2>
        </div>
        <Button
          onClick={() => setNewGroupDialogOpen(true)}
          size="medium"
          startIcon={<Add16 />}
        >
          Create group
        </Button>
        <GroupList />
        <NewGroupFormDialog
          newGroupDialogOpen={newGroupDialogOpen}
          setNewGroupDialogOpen={setNewGroupDialogOpen}
        />
      </CardContent>
    </Card>
  );
};

export default GroupsOverview;
