import React, { PropsWithChildren } from "react";
import Header from "../components/Header/Header";
import MainWindow from "../components/Layout/MainWindow";
import { Outlet } from "react-router-dom";

const RootLayout: React.FC<PropsWithChildren> = (props) => {
  return (
    <div style={{ marginTop: "56px" }}>
      <Header />
      <MainWindow>
        <Outlet />
      </MainWindow>
    </div>
  );
};

export default RootLayout;
