import { Button, TextFilter } from "@yunex/yds-react";
import AccessManagementContext from "../../../store/access-management-context";
import { useContext, useEffect, useState } from "react";
import { GroupBody } from "./Group";
import { ColDef, FirstDataRenderedEvent } from "ag-grid-community";
import { Edit16 } from "@yunex/yds-icons";
import { AgGridReact } from "ag-grid-react";
import NewGroupFormDialog from "../FormDialogs/NewGroupFormDialog";

const GroupList = () => {
  const context = useContext(AccessManagementContext);
  const [editGroupDialogOpen, setEditGroupDialogOpen] = useState(false);
  const [rowData, setRowData] = useState<GroupBody[] | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<GroupBody | undefined>(
    undefined
  );

  useEffect(() => {
    setRowData(context.groups.value);
  }, [context.groups.value]);

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    params.api?.sizeColumnsToFit();
  };

  const handleEdit = (group: GroupBody) => {
    setSelectedGroup(group);
    setEditGroupDialogOpen(true);
  };
  const ContextActionsRenderer = (group: GroupBody) => {
    return (
      <>
        <Button
          aria-label="button-edit"
          icon
          color="tertiary"
          onClick={() => handleEdit(group)}
        >
          <Edit16 />
        </Button>
      </>
    );
  };

  const [defaultColDef] = useState<ColDef>({
    filter: TextFilter,
    resizable: true,
    sortable: true,
  });

  const [columnDefs] = useState<ColDef<any>[]>([
    {
      field: "name",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "",
      cellRenderer: (props: any) => {
        return ContextActionsRenderer(props.data);
      },
      maxWidth: 60,
      filter: false,
      resizable: false,
      sortable: false,
    },
  ]);
  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          height: "60vh",
          width: "100%",
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onFirstDataRendered={onFirstDataRendered}
          rowData={rowData}
        />
      </div>
      {selectedGroup && (
        <NewGroupFormDialog
          newGroupDialogOpen={editGroupDialogOpen}
          setNewGroupDialogOpen={setEditGroupDialogOpen}
          group={selectedGroup}
        />
      )}
    </>
  );
};

export default GroupList;
