import { Logout16 } from "@yunex/yds-icons";
import {
  Avatar,
  Box,
  ListItemIcon,
  MenuItem,
  MenuItemText,
  PersonName,
  TopNavButton,
  UserMenu,
} from "@yunex/yds-react";
import { MouseEventHandler, useState } from "react";
import { Form, useRouteLoaderData } from "react-router-dom";

export type AuthUser = {
  authenticated: boolean;
  user: {
    username?: string;
    email?: string;
    roles: string[];
  };
};

const UserNav = () => {
  const rootLoaderData = useRouteLoaderData("root") as AuthUser;

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const user = rootLoaderData.user;
  const firstTwoChars = user.username!.slice(0, 2).toUpperCase();

  const username: string = user.username!;
  const avatar: string = firstTwoChars;

  const handleProfileMenuOpen: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex">
      <TopNavButton
        aria-label="user-menu-button"
        onClick={handleProfileMenuOpen}
        style={{
          marginLeft: "auto",
          flex: 0,
        }}
      >
        <Avatar alt={username}></Avatar>
      </TopNavButton>
      <UserMenu
        anchorEl={anchorEl}
        onClose={handleProfileMenuClose}
        data-testid="user-menu"
        id="user-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorEl)}
      >
        <PersonName
          avatar={avatar}
          name={username}
          style={{
            width: "12.5rem",
          }}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        />

        <Form action="/logout" method="post">
          <label>
            <input type="submit" style={{ display: "none" }} />
            <MenuItem danger>
              <ListItemIcon>
                <Logout16 />
              </ListItemIcon>
              <MenuItemText
                primary="Logout"
                primaryTypographyProps={{
                  fontWeight: "medium",
                }}
              />
            </MenuItem>
          </label>
        </Form>
      </UserMenu>
    </Box>
  );
};

export default UserNav;
