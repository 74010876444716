export const authProvider = {
  isAuthenticated: false,
  user: {
    username: null,
    email: null,
    roles: [],
  },
  setAuthentication(user: { username: any; email: any; roles: any }) {
    const { username, email, roles } = user;
    authProvider.isAuthenticated = true;
    authProvider.user.username = username;
    authProvider.user.email = email;
    authProvider.user.roles = roles || [];
  },
  clear() {
    authProvider.isAuthenticated = false;
    authProvider.user = {
      username: null,
      email: null,
      roles: [],
    };
  },
};
