import React, { PropsWithChildren } from "react";
import Header from "../Header/Header";

const MainWindow: React.FC<PropsWithChildren> = (props) => {
  return (
    <div style={{ marginTop: "56px" }}>
      <Header />
      {props.children}
    </div>
  );
};

export default MainWindow;
