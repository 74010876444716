import { Button, Card, CardContent } from "@yunex/yds-react";
import UserList from "./UserList";
import { Add16 } from "@yunex/yds-icons";
import NewUserFormDialog from "../FormDialogs/NewUserFormDialog";
import { useState } from "react";

const UsersOverview = () => {
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);

  return (
    <Card style={{ height: "100%", width: "100%" }}>
      <CardContent>
        <div>
          <h2>Users</h2>
        </div>
        <Button
          onClick={() => setNewUserDialogOpen(true)}
          size="medium"
          startIcon={<Add16 />}
        >
          Create user
        </Button>
        <UserList />
        <NewUserFormDialog
          newUserDialogOpen={newUserDialogOpen}
          setNewUserDialogOpen={setNewUserDialogOpen}
        />
      </CardContent>
    </Card>
  );
};

export default UsersOverview;
