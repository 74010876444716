import { json, redirect } from "react-router-dom";
import { authProvider } from "./auth-provider";

export const protectedLoader = ({ request }: any) => {
  if (authProvider.isAuthenticated) return null;

  const { pathname, search } = new URL(request.url);
  const params = new URLSearchParams();

  params.set("from", pathname + search);
  return redirect("/login?" + params.toString());
};

export const loginLoader = async ({ request }: any) => {
  if (authProvider.isAuthenticated) {
    return redirect("/");
  }

  const location = new URL(request.url);
  const from = location.searchParams.get("from") || "/";

  const response = await fetch("/api/login" + location.search);
  if (!response.ok) {
    throw json(
      { message: "AN error occurred while logging in" },
      { status: 500 }
    );
  }

  const responseData = await response.json();
  if (responseData.authenticated) {
    authProvider.setAuthentication(responseData.user);
    return redirect(from);
  } else {
    return redirect(responseData.redirect_url);
  }
};
