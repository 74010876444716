import { StatusPage } from "@yunex/yds-react";

const ErrorPage = () => {
  return (
    <>
      <StatusPage
        title="Page not found (404)"
        description="Check the URL address and try again."
      />
    </>
  );
};

export default ErrorPage;
