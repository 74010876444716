import ContentTitle from "../components/Layout/ContentTitle";
import ContentWindow from "../components/Layout/ContentWindow";
import SideBar from "../components/Layout/SideBar";
import { ListItemIcon, SideNavItem, SideNavText } from "@yunex/yds-react";
import { User16, UserGroup16 } from "@yunex/yds-icons";
import { NavLink, Outlet } from "react-router-dom";
import AccessManagementProvider from "../store/AccessManagementProvider";

const AccessManagementPage = () => {
  type NavItem = {
    text: string;
    icon: JSX.Element;
    link: string;
  };
  const navItems: NavItem[] = [
    {
      text: "Users",
      icon: <User16 />,
      link: "/access-management/users",
    },
    {
      text: "Groups",
      icon: <UserGroup16 />,
      link: "/access-management/groups",
    },
  ];

  const navElements = navItems.map((item) => {
    return (
      <NavLink
        key={item.text}
        to={item.link}
        style={{ textDecoration: "none" }}
      >
        {({ isActive }) => (
          <SideNavItem
            sx={{ marginTop: "1em" }}
            key={item.text}
            selected={isActive}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <SideNavText primary={item.text} />
          </SideNavItem>
        )}
      </NavLink>
    );
  });

  return (
    <>
    <AccessManagementProvider>
      <ContentTitle>
        <h2>Access management</h2>
      </ContentTitle>
      <ContentWindow>
        <SideBar>{navElements}</SideBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "2em",
            padding: "2em",
          }}
        >
          <Outlet />
        </div>
      </ContentWindow>
    </AccessManagementProvider>
    </>
  );
};

export default AccessManagementPage;
