import React from "react";
import {
  RoleRepresentation,
  UserDoc,
} from "../components/Management/Users/User";
import { GroupBody } from "../components/Management/Groups/Group";

export type AccessManagementContextType = {
  users: {
    value?: UserDoc[];
    set: () => void;
  };
  groups: {
    value?: GroupBody[];
    set: () => void;
  };
  roles: {
    value?: RoleRepresentation[];
    set: () => void;
  };
};

const AccessManagementContext =
  React.createContext<AccessManagementContextType>({
    users: {
      value: undefined,
      set: () => {},
    },
    groups: {
      value: undefined,
      set: () => {},
    },
    roles: {
      value: undefined,
      set: () => {},
    },
  });

export default AccessManagementContext;
