import { redirect, json } from "react-router-dom";
import { authProvider } from "./auth-provider";

export const logoutAction = async () => {
  const response = await fetch("/api/logout", { method: "post" });

  if (!response.ok) {
    throw json(
      { message: "AN error occured while signing out" },
      { status: 500 }
    );
  }

  authProvider.clear();
  return redirect("/");
};
