import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import RootLayout from "./routes/RootLayout";
import ErrorPage from "./routes/ErrorPage";
import AccessManagementPage from "./routes/AccessManagementPage";
import UsersOverview from "./components/Management/Users/UsersOverview";
import GroupsOverview from "./components/Management/Groups/GroupsOverview";
import { authProvider } from "./util/auth/auth-provider";
import { loginLoader, protectedLoader } from "./util/auth/auth-loaders";
import { logoutAction } from "./util/auth/auth-actions";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader: () => ({
      isAuthenticated: authProvider.isAuthenticated,
      user: authProvider.user,
    }),
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, loader: () => redirect("/access-management/users") },
      {
        path: "login",
        loader: loginLoader,
      },
      {
        path: "access-management",
        loader: protectedLoader,
        element: <AccessManagementPage />,
        children: [
          { path: "users", element: <UsersOverview /> },
          { path: "groups", element: <GroupsOverview /> },
        ],
      },
      { path: "logout", action: logoutAction },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
