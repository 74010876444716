import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  AdapterDayjs,
  LocalizationProvider,
  ThemeProvider,
  Theme,
} from "@yunex/yds-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider defaultTheme={Theme.Light}>
        <App />
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
