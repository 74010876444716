import { StatusInfo16, Trash16 } from "@yunex/yds-icons";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  SectionField,
  TextField,
  TextFormInput,
} from "@yunex/yds-react";
import { useTheme } from "@yunex/yds-react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { RoleRepresentation, UserDoc } from "../Users/User";
import { GroupBody } from "../Groups/Group";
import AccessManagementContext from "../../../store/access-management-context";

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const NewGroupFormDialog: React.FC<{
  newGroupDialogOpen: boolean;
  setNewGroupDialogOpen: Dispatch<SetStateAction<boolean>>;
  group?: GroupBody;
}> = (props) => {
  const context = useContext(AccessManagementContext);

  const { palette } = useTheme();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [rolesProd, setRolesProd] = useState<RoleRepresentation[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserDoc[]>([]);
  const [users, setUsers] = useState<UserDoc[]>([]);
  const [selectedRolesProd, setSelectedRolesProd] = useState<
    RoleRepresentation[]
  >([]);
  const [rolesAccp, setRolesAccp] = useState<RoleRepresentation[]>([]);
  const [selectedRolesAccp, setSelectedRolesAccp] = useState<
    RoleRepresentation[]
  >([]);
  const [rolesTest, setRolesTest] = useState<RoleRepresentation[]>([]);
  const [selectedRolesTest, setSelectedRolesTest] = useState<
    RoleRepresentation[]
  >([]);

  const dialogTitle = props.group ? "Edit group" : "Create group";

  useEffect(() => {
    const setAllRoles = async () => {
      if (!context.roles.value) return;
      const roleProd = context.roles.value.filter((val) =>
        val.name.endsWith("_production")
      );
      if (roleProd)
        setRolesProd(roleProd.filter((val) => val.name.startsWith("vehicle:")));
      const roleAccp = context.roles.value.filter((val) =>
        val.name.endsWith("_acceptance")
      );
      if (roleAccp)
        setRolesAccp(roleAccp.filter((val) => val.name.startsWith("vehicle:")));
      const roleTest = context.roles.value.filter((val) =>
        val.name.endsWith("_testbed")
      );
      if (roleTest)
        setRolesTest(roleTest.filter((val) => val.name.startsWith("vehicle:")));
    };
    setAllRoles();

    if (!context.users.value) return;
    setUsers(context.users.value);
  }, [context.users.value, context.roles.value]);

  const onCloseHandler = async () => {
    props.setNewGroupDialogOpen(false);

    await sleep(200);

    dialogReset();
    context.groups.set();
    setIsConfirming(false);
  };

  const onCloseWithConfirmHandler = async () => {
    onCloseHandler();
  };

  const dialogReset = () => {
    reset({
      groupName: "",
    });
    setSelectedRolesProd([]);
    setSelectedRolesAccp([]);
    setSelectedRolesTest([]);
  };

  const onSubmitHandler = async (data: any) => {
    var groupRoles: GroupBody["values"]["roles"] = [];
    selectedRolesProd.map((role) => {
      return groupRoles?.push({ id: role.id, name: role.name });
    });
    selectedRolesAccp.map((role) => {
      return groupRoles?.push({ id: role.id, name: role.name });
    });
    selectedRolesTest.map((role) => {
      return groupRoles?.push({ id: role.id, name: role.name });
    });

    var users: GroupBody["values"]["users"] = selectedUsers.map((user) => {
      return { id: user.id!, username: user.username! };
    });

    let groupId: string | undefined = undefined;
    if (props.group) groupId = props.group.id;

    const group: GroupBody = {
      id: groupId,
      name: data.groupName,
      values: {
        roles: groupRoles,
        users: users,
      },
    };
    var requestOptions;
    if (groupId) {
      requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(group),
      };
    } else {
      requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(group),
      };
    }
    await fetch("/api/group", requestOptions);
    setIsConfirming(true);
    if (isEdited) {
    }
    onCloseHandler();
  };

  const onDeleteHandler = async () => {
    if (!window.confirm(`Are you sure you want to delete this group?`)) return;

    setIsConfirming(true);

    const response = await fetch("/api/group/" + props.group!.id, {
      method: "DELETE",
    });
    const responseData = await response.json();
    if (response.status !== 200) {
      console.error(responseData);
      alert("Could not delete group:\n\n" + responseData.message);
      setIsConfirming(false);
      return;
    } else {
      await onCloseHandler();
    }
  };

  const schema = yup.object().shape({
    groupName: yup
      .string()
      .trim()
      .required("Enter a group name")
      .max(30, "First name should not be longer then 30 characters"),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      groupName: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    if (props.group) {
      if (props.group.name) setValue("groupName", props.group.name);
      if (props.group.values && props.group.values.roles) {
        var roles = props.group.values.roles;

        var rolesProd = roles.filter((val) => val.name.endsWith(`_production`));
        var rolesAccp = roles.filter((val) => val.name.endsWith(`_acceptance`));
        var rolesTest = roles.filter((val) => val.name.endsWith(`_testbed`));

        setSelectedRolesProd(rolesProd);
        setSelectedRolesAccp(rolesAccp);
        setSelectedRolesTest(rolesTest);
      }
      if (props.group.values && props.group.values.users) {
        setSelectedUsers(
          props.group.values.users.map((val) => {
            return {
              id: val.id,
              username: val.username,
              groups: [],
              roles: [],
            };
          })
        );
      }
    }
  }, [props.newGroupDialogOpen, props, setValue]);

  return (
    <Dialog
      fullWidth={true}
      open={props.newGroupDialogOpen}
      onClose={onCloseWithConfirmHandler}
    >
      <DialogTitle
        icon={StatusInfo16}
        iconProps={{ sx: { fill: palette.primary.main } }}
        actionButton={
          props.group && {
            icon: <Trash16 color="red" />,
            ariaLabel: "remove-group",
            onClick: onDeleteHandler,
          }
        }
      >
        {dialogTitle}
      </DialogTitle>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <DialogContent>
          <SectionField insets value="Group details">
            <Grid item xs={12} sm={12}>
              <Controller
                {...{ control }}
                name="groupName"
                render={({ field }) => (
                  <TextFormInput
                    {...field}
                    onChange={(event: any) => {
                      field.onChange(event);
                      setIsEdited(true);
                    }}
                    errorMessage={errors.groupName?.message}
                    label="Group name"
                    required
                  />
                )}
              />
            </Grid>
          </SectionField>
          <SectionField insets value="Roles">
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedRolesProd}
                  options={rolesProd}
                  onChange={(_event, newValue) => {
                    setSelectedRolesProd(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    let name = option.name
                      .replace(/vehicle:/g, "")
                      .replace(/_production/g, "");
                    let displayName =
                      name.charAt(0).toUpperCase() + name.slice(1);
                    return displayName;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Production roles" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedRolesAccp}
                  options={rolesAccp}
                  onChange={(_event, newValue) => {
                    setSelectedRolesAccp(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    let name = option.name
                      .replace(/vehicle:/g, "")
                      .replace(/_acceptance/g, "");
                    let displayName =
                      name.charAt(0).toUpperCase() + name.slice(1);
                    return displayName;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Acceptance roles" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  filterSelectedOptions
                  value={selectedRolesTest}
                  options={rolesTest}
                  onChange={(_event, newValue) => {
                    setSelectedRolesTest(newValue);
                    setIsEdited(true);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    let name = option.name
                      .replace(/vehicle:/g, "")
                      .replace(/_testbed/g, "");
                    let displayName =
                      name.charAt(0).toUpperCase() + name.slice(1);
                    return displayName;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Testbed roles" />
                  )}
                />
              </Grid>
            </Grid>
          </SectionField>

          <SectionField insets value="Users">
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                filterSelectedOptions
                value={selectedUsers}
                options={users}
                onChange={(_event, newValue) => {
                  setSelectedUsers(newValue);
                  setIsEdited(true);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.username!}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Users" />
                )}
              />
            </Grid>
          </SectionField>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting || isConfirming}
            type="submit"
          >
            Save
          </Button>
          <Button
            color="secondary"
            disabled={isSubmitting || isConfirming}
            onClick={onCloseWithConfirmHandler}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NewGroupFormDialog;
